/*
 * @Author: your name
 * @Date: 2021-04-17 10:06:19
 * @LastEditTime: 2021-04-29 14:45:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/config.js
 */


export const config = {
	baseImgUrl:'https://xsjy-1254153797.cos.ap-shanghai.myqcloud.com/pc/lpt-edu-pc/imgs/',
	baseUrl:'https://edu.liupinedu.com'
}

