<!--
 * @Author: your name
 * @Date: 2021-04-17 14:38:25
 * @LastEditTime: 2021-05-28 17:24:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/components/header.vue
-->

<template>
	<div class="header fff_bg">
		<div class="top fff default_bg">
			<div class="top_center d_flex justify_content_between width_1200 margin_auto align_items_center">
				<div>专注6-12岁少儿书法教育</div>
				<div><span>加盟热线</span> <img :src="baseImgUrl+'phone_icon.png'" alt=""> <b v-if="baseInfo">{{baseInfo.join_tel}}</b> </div>
			</div>
		</div>
		<div class="content fff_bg">
			<div class="content_center d_flex justify_content_between width_1200 margin_auto align_items_center">
				<img @click="toHome()" class="logo" v-if="baseInfo" :src="baseInfo.logo" alt="">
				<div class="menu d_flex">
					<div :class="['menu_item' , path === item.url ? 'hover_class' : item.urlArr && item.urlArr.indexOf(path) > -1 ? 'hover_class' : '' ]"
						v-for="(item) in menu" 
						:key="item.id" 
						@click="toLink(item.url , item.id)">
						<span>{{item.name}}</span>
						<div v-if="item.child" class="menu_item_child">
							<img :src="baseImgUrl + 'h_menu_img.png'" alt="">
							<div>
								<p v-for="(itemChild) in item.child" 
									:key="itemChild.id" 
									:class="[childId === itemChild.id ? 'hover_class' : '']" 
									@click="toLink(itemChild.url , itemChild.id)"
									@mouseover="childHoverOver(itemChild.id)"
									@mouseout="childHoverOut()"
									>{{itemChild.name}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { config } from '../config';
	export default {
		props:{
			baseInfo:{
				type:Object,
				default:null
			}	
		},
		data () {
			return {
				path:'',
				baseImgUrl : config.baseImgUrl,
				logo:'',
				joinTel:'',
				childId:-1,
				menu:[
					{name:'首页' , id:1 , url:'/home'},
					{
						name:'课程体系' ,
						id:2 , 
						urlArr:['/course'],
						child:[
							{
								name:'硬笔课程',
								id:20,
								url:'/course?type=1'
							},
							{
								name:'软笔课程',
								id:21,
								url:'/course?type=2'
							},
							{
								name:'国画课程',
								id:22,
								url:'/painting'
							}
						]
					},
					{
						name:'综合展示' , 
						id:3 ,
						urlArr:['/history' , '/teamwork' , '/production'],
						child:[
							{
								name:'发展历程',
								id:30,
								url:'/history'
							},
							{
								name:'合作案例',
								id:31,
								url:'/teamwork'
							},
							{
								name:'发展作品',
								id:32,
								url:'/production'
							}
						]
					},
					{name:'新闻中心' , id:4 , url:'/news'},
					{name:'加盟合作' , id:5 , url:'/experience.html'},
					{name:'关于我们' , id:6 , url:'/about'},
				],
				urlActive:false,
				// myBaseInfo:null
			};
		},

		created(){
			if(this.$route.path){
				this.path = this.$route.path
			}
			
		},
		// watch:{
		// 	baseInfo(){
		// 		if(this.baseInfo){
		// 			this.myBaseInfo = this.baseInfo;
		// 			console.log(this.myBaseInfo);
		// 		}
		// 	}
		// },
		methods: {
			toLink(url,id){
				if(url){
					switch(id){
						case 5:
							let wPath = window.document.location.href;
							let pathName =  this.$route.path;
  							let pos = wPath.indexOf(pathName);
  							let localhostPath = wPath.substring(0, pos);
							window.open(localhostPath + url);
							break;
						case 20:
						case 21:
							if(this.$route.query.type == url.split('?')[1].split('=')[1] && this.path == url.split('?')[0]){
								return
							}
							this.$router.push({
								path:url
							});
							break;
						default:
							if(url !== this.path){
								this.$router.push({
									path:url
								})
							}
					}
				}
			},
			toHome(){
				this.$router.push({
					path:'/'
				});
			},

			childHoverOver(id){
				this.childId = id
			},

			childHoverOut(){
				this.childId = -1
			}
			
		}
	}

</script>
<style lang='scss' scoped>
	.header{
		position: sticky;
		top:0;
		z-index: 100;
		.top{
			height: 40px;
			font-size: 16px;
			.top_center{
				height:100%;
				img{
					width: 20px;
					height: 20px;
					margin: 0 0 0 10px;
				}
				b{
					vertical-align: text-top;
					font-weight: bold;
				}
				span{
					vertical-align: text-top;
				}
			}
		}
		.content{
			font-size:18px;
			color:"#333333";
			height: 80px;
			box-shadow: 0 3px 6px rgba(0,0,0,.16);
			.content_center{
				height: 100%;
				.logo{
					width: 160px;
					height: 60px;
					cursor: pointer;
				}
				.menu{
					.menu_item{
						padding:0 24px;
						cursor: pointer;
						border-right: 1px solid #333333;
						position: relative;
						.menu_item_child{
							// opacity: 0;
							left:0;
							position: absolute;
							top:26px;
							height:0;
							transition: all .5s ease 0s;
							overflow: hidden;
							color:#333;
							img{
								width: 80px;
								height: 22px;
								display: block;
								margin:0 auto;
							}
							div{
								width: 121px;
								padding: 8px 0;
								background: #fff;
								border:1px solid #B91829;
								p{
									text-align: center;
									line-height: 34px;
								}
								p.hover_class{
									color:#B91829;
								}
							}
						}
					}
					.menu_item:last-child{
						border:none;
						padding-right: 0;
					}
					.menu_item.hover_class{
						span{
							color:#B91829;
						}
					}
					.menu_item:hover{
						span{
							color:#B91829;
						}
						.menu_item_child{
							height:200px;
							// opacity: 1;
							overflow: visible;
						}
					}
				}
			}
			
		}
	}
	
</style>



