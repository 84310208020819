/*
 * @Author: your name
 * @Date: 2021-04-17 11:01:10
 * @LastEditTime: 2021-04-29 15:22:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/utils/request.js
 */

import axios from "axios";

import { Message } from 'element-ui';

export const fetch = function(options){

	let token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
	
	return new Promise((resolve , reject) => {
		
		const instance = axios.create({
			headers : {
				contentType : options.contentType ? options.contentType : "application/json",
				Authorization : token
			},
			timeout:3000,
			baseURL:process.env.VUE_APP_BASE_API
		});

		instance.interceptors.request.use(config => {
			return config
		},error => {
			reject(error);
		});
		instance(options).then(response => {
			let res = null;
			switch (response.status){
				case 200:
					res = response.data;
					resolve(res);
					break;
				case 403:
					Message({
						message : '服务器请求超时',
						type : 'error'
					})
					break;
				case 500:
					Message({
						message : '服务器错误',
						type : 'error'
					})
					break;
				default:
					break 
			}
		}, error => {
			Message({
				message : '请求失败:'+error,
				type : 'error'
			})
		}).catch(error => {
			console.log(error);
		});

	})

}



 