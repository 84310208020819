/*
 * @Author: your name
 * @Date: 2021-04-16 16:24:47
 * @LastEditTime: 2021-05-25 16:33:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_dev_pc/src/router/index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
		path: "/",
		name: "Home",
		component: Home,
		redirect:'/home'
	},
	{
		path: "/home",
		name: "Home",
		component: Home,
    },
    {
		path: "/about",
		name: "About",
		component: () =>
			import("../views/About.vue"),
	},
	{
		path: "/course",
		name: "Course",
		component: () =>
			import("../views/Course.vue"),
	},
	{
		path:"/production",
		name:"Production",
		component: () =>
			import("../views/Production.vue")
	},
	{
		path:"/news",
		name:"News",
		component: () =>
			import("../views/News.vue")
	},
	{
		path:"/newsDetail",
		name:"NewsDetail",
		component: () =>
			import("../views/NewsDetail.vue")
	},
	{
		path:"/history",
		name:"History",
		component: () =>
			import("../views/History.vue")
	},
	{
		path:"/teamwork",
		name:"Teamwork",
		component: () =>
			import("../views/Teamwork.vue")
	},
	{
		path: "/painting",
		name: "Painting",
		component: () =>
			import("../views/painting.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(){
		return { x: 0, y: 0 }
	}
});

export default router;
