/*
 * @Author: your name
 * @Date: 2021-04-17 13:30:03
 * @LastEditTime: 2021-05-27 23:01:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/utils/api.js
 */
import {fetch} from './request';

export const sendCode = function(data){
	return fetch({
		url:'/send-code',
		method:'post',
		data
	})
}

export const add = function(data){
	return fetch({
		url:'/verify-code',
		method:'post',
		data
	})
} 

export const adver = function(data){
	return fetch({
		url:'/api/web-setting/seo/adver',
		method:'post',
		data
	})
}

export const article = function(data){
	return fetch({
		url:'/api/web-setting/seo/article',
		method:'post',
		data
	})
}

export const articleDetail = function(data){
	return fetch({
		url:'/api/web-setting/seo/article/details',
		method:'post',
		data
	})
}

export const setSeo = function(){
	return fetch({
		url:"/api/web-setting/seo/setting",
		method:'get'
	})
}

export const infos = function(){
	return fetch({
		url:'/api/web-setting/info',
		method:'get'
	})
}