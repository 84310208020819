<!--
 * @Author: your name
 * @Date: 2021-04-21 17:44:11
 * @LastEditTime: 2021-05-27 21:10:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/components/Top.vue
-->
<template>
	<div class="to_top" :style="'background:url('+baseImgUrl+'top_bg.png);background-size:contain;background-repeat:no-repeat;'">
		<img class="to_top_welcome" :src="baseImgUrl + 'top_welcome.png'" alt="">
		<div class="to_top_items">
			<div class="to_top_item" @click="toLink()">
				<img :src="baseImgUrl + 'top_message.png'" alt="">
			</div>
			<div class="to_top_item top_wechat_box">
				<img :src="baseImgUrl + 'top_wechat.png'" alt="">
				<div class="to_wechat" :style="'background:url('+baseImgUrl+'qr_code_bg.png);background-size:contain;'">
					<img v-if="baseInfo" :src="baseInfo.qrcode" alt="">
				</div>
			</div>
			<div class="to_top_item top_tel_box">
				<img :src="baseImgUrl + 'top_tel.png'" alt="">
				<div class="to_tel" :style="'background:url('+baseImgUrl+'top_tel_bg.png);background-size:contain;'">
					<p v-if="baseInfo">{{baseInfo.join_tel}}</p>
				</div>
			</div>
			<div class="to_top_item to_top_boult">
				<img :src="baseImgUrl + 'top_icon.png'" alt="">
			</div>
		</div>
		<span class="to_top_model" @click="toTop()"></span>
	</div>
</template>

<script>
import {config} from '../config';
export default {
	props:{
		baseInfo:{
			type:Object,
			default:null
		}
	},
	data () {
		return {
			baseImgUrl:config.baseImgUrl,
			
		};
	},

	created(){
		
	},

   methods: {
	   toTop(){
		   	let distance = document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
			let step = distance / 30; //每步的距离
			(function jump() {
				if (distance > 0) {
					distance -= step;
					window.scrollTo(0, distance);
					setTimeout(jump, 20);
				}
			})();
		},
	   toLink(){
		//    this.$emit('openPop')
		   window.open('https://tb.53kf.com/code/client/1c4c12de26983bf3f0db545fe5650f346/1');
	   }
   }
}

</script>
<style lang='scss' scoped>
	.to_top{
		position: fixed;
		right: 5px;
		bottom:140px;
		width:78px;
		height:290px;
		z-index: 1000;
		background: #C64042;
		.to_top_welcome{
			width:88px;
			height:103px;
			position: absolute;
			top:-33px;
			left:-13px;
		}
		.to_top_items{
			margin-top:70px;
			.to_top_item{
				position: relative;
				width: 34px;
				height:34px;
				margin:14px auto 0;
				img{
					width: 100%;
					height:100%;
				}
				.to_wechat{
					width:148px;
					height:130px;
					position: absolute;
					left:-180px;
					top:-70px;
					display: none;
					img{
						width: 100px;
						height: 100px;
						display: block;
						margin:15px 0 0 20px;
					}
				}
				.to_tel{
					width:148px;
					height:40px;
					position: absolute;
					left:-180px;
					top:-5px;
					display: none;
					p{
						text-align: center;
						color: #fff;
						line-height: 40px;
						font-weight: bold;
						font-size: 16px;
					}
				}
			}
			.to_top_item:first-child{
				cursor: pointer;
			}
			.top_wechat_box:hover{
				.to_wechat{
					display: block;
				}
			}
			.top_tel_box:hover{
				.to_tel{
					display: block;
				}
			}
			.to_top_boult{
				width:26px;
				height:26px;
				margin-top: 20px;
			}
		}
		.to_top_model{
			position: absolute;
			width:78px;
			height:70px;
			bottom: 0;
			left:0;
			border-radius: 0 0 39px 39px;
			cursor: pointer;
		}
	}
</style>
