<!--
 * @Author: your name
 * @Date: 2021-04-16 16:24:47
 * @LastEditTime: 2021-06-09 10:28:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_dev_pc/src/views/Home.vue
-->
<template>
	<div class="home">
		<Header :baseInfo="baseInfo"/>
		<div class="main" style="position:relative">
			<div class="swiper_banner" v-if="banner.length > 0" @mouseenter="on_swiper_enter" @mouseleave="on_swiper_leave">
				<swiper ref="mySwiperBanner" :options="swiperBanner" v-if="banner.length > 1">
					<swiper-slide v-for="(item , index) in banner" :key="index">
						<img style="width:100%;height:100%;" :src="item.pic" alt="">
					</swiper-slide>
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
				<img @click="adDetail(banner[0].url)" style="width:100%;" v-else :src="banner[0].pic" alt="">
			</div>

			<div class="home_1" ref="home_1" :style="'background:url('+bg1+');backgroundSize:cover'">
				<div class="home_1_con width_1200 margin_auto d_flex justify_content_between align_items_center">
					<div :class="['video_box']">
						<div class="v_container">
							<video class="video" controls="true" src="https://file.liupinshuyuan.com/jpvideo.mp4" :autoplay="false"></video>
							<div class="v_bg default_bg"></div>
						</div>
					</div>
					<div :class="['right']">
						<h4>书法教育综合服务平台</h4>
						<b class="default_bg"></b>
						<div>
							<p>六品书院定位于一站式创新书法教育综合服务平台，产品整合“教研、教学、服务、营销、管理”五大板块，以书法学习为基础，以互联网为核心，为线下教培机构提供现代化的书法教育解决方案。</p>
							<p>自2020年开放加盟以来，目前加盟校已经覆盖全国22个省，4个自治区，4个直辖市，以燎原之势席卷大江南北。</p>
						</div>
					</div>
				</div>
			</div>
			<el-image class="slogn" :src="baseImgUrl + 'slogn.png'" :lazy="true"></el-image>
			<div :class="['home_2' ]">
				<div :class="['before' , 'a_bounce' , 'animate__animated' , active_a_1 ? 'animate__bounceInUp':'' ]" ref="a_1" :style="'background:url('+bg2+');backgroundSize:cover'">
					<h4 class="home_title">完善的、循序渐进的课程体系、教材系统</h4>
					<!-- <p class="tixi">全面覆盖5~12岁孩子从启蒙到深入，硬笔和软笔的书法教育需求</p> -->
					<el-image @click="toCourse()" style="cursor:pointer" class="width_1200 display_block margin_auto" :lazy="true" :src="baseImgUrl + 'home_course_banner.png'" alt=""></el-image>
				</div>
				<div v-if="gameLists.length > 0" :class="['before' , 'a_bounce' , 'animate__animated' , active_a_2 ? 'animate__bounceInUp' : '']" ref="a_2">
					<h4 class="home_title">全国书法练字大赛</h4>
					<div class="home_game width_1200 margin_auto d_flex justify_content_between">
						<div class="left">
							<div @mouseover="tabMouseOver(index)" @click="adDetail(item.url)" v-for="(item,index) in gameLists" :key="index" :class="['d_flex','tabs_card']" :style=" tabCardActive === index ? 'background:url('+tabs_card_bg+');background-size:contain;background-repeat:no-repeat;color:#fff;' : 'background:transparent'">
								<div class="d_flex justify_content_center align_items_center">
									<b :class="['triangle', item.title.length > 19 ? '' : 'one_line']" :style="tabCardActive === index ? 'background:#fff' : '' "></b>
									<div class="line_content">
										<p class="line_two_clamp">{{item.title}}</p>
									</div>
								</div>
							</div>
						</div>
						<div>
							<img class="right_img" @click="adDetail(item.url)" v-show="tabCardActive === index" v-for="(item , index) in gameLists" :key="index" :src="item.pic" alt="">
						</div>
						<!-- <swiper class="swiper_banner_pagination" :options="swiperBanner">
							<swiper-slide v-for="(item , index) in banner" :key="index">
								<img class="right_img" style="width:100%;height:100%;" :src="baseImgUrl + item" alt="">
							</swiper-slide>
							<div class="swiper-pagination" slot="pagination"></div>
						</swiper> -->
					</div>
				</div>
			</div>
			<div class="home_3" :style="'background:url('+bg3+');backgroundSize:cover'">
				<div :class="['before' , 'a_bounce' , 'animate__animated' , active_a_3 ? 'animate__bounceInUp':'' ]" ref="a_3">
					<div>
						<h4 class="home_title">书法教育全新布局，双师模式解决方案</h4>
						<p style="color:#999;font-size:20px;margin-top:20px;">无需经验，轻松办学</p>
					</div>
					<div class="teachers_layout width_1200 margin_auto d_flex">
						<div v-for="item in tea_list" :key="item.id" class="t_layout_item_box" @click="toJiaMeng()">
							<div class="t_layout_item d_flex flex_column justify_content_center align_items_center">
								<img :src="baseImgUrl + item.url" alt="">
								<b></b>
								<h4>{{item.title}}</h4>
								<div>
									<p>{{item.content1}}</p>
									<p>{{item.content2}}</p>
								</div>
								<p class="know_more">了解更多</p>
							</div>
							<div class="t_layout_item_hover">
								<p>{{item.content3}}</p>
							</div>
						</div>
					</div>
				</div>
				<div :class="['before' , 'a_bounce' , 'animate__animated' , active_a_4 ? 'animate__bounceInUp':'' ]" ref="a_4">
					<h4 class="home_title">提供六大赋能体系</h4>
					<div class="team_work  width_1200 margin_auto d_flex_row_end" :style="'background:url('+teamWorkBg+');backgroundSize:contain;backgroundRepeat: no-repeat;'">
						<div class="team_work_content d_flex flex_wrap justify_content_around">
							<div class="team_work_item item_first d_flex_column_start_center">
								<div>
									<span class="vertical_align_middle">六大赋能体系</span>
									<img class="vertical_align_middle" :src="baseImgUrl+'home_right_icon.png'" alt="">
								</div>
								<b></b>
							</div>
							<div class="team_work_item item_pic">
								<el-image :lazy="true" :src="baseImgUrl + 'home_team_1.png'" alt=""></el-image>
								<p>师训支持</p>
							</div>
							<div class="team_work_item item_pic">
								<el-image :lazy="true" :src="baseImgUrl + 'home_team_2.png'" alt=""></el-image>
								<p>招生支持</p>
							</div>
							<div class="team_work_item item_pic">
								<el-image :lazy="true" :src="baseImgUrl + 'home_team_3.png'" alt=""></el-image>
								<p>品牌支持</p>
							</div>
							<div class="team_work_item item_pic">
								<el-image :lazy="true" :src="baseImgUrl + 'home_team_4.png'" alt=""></el-image>
								<p>教研支持</p>
							</div>
							<div class="team_work_item item_pic">
								<el-image :lazy="true" :src="baseImgUrl + 'home_team_5.png'" alt=""></el-image>
								<p>运营支持</p>
							</div>
							<div class="team_work_item item_pic">
								<el-image :lazy="true" :src="baseImgUrl + 'home_team_6.png'" alt=""></el-image>
								<p>技术支持</p>
							</div>
							<div class="team_work_item item_last d_flex_column_center">
								<p>给予机会，抓住新时代 <br/> 教育红利</p>
								<button @click="toJiaMeng()">更多详情</button>
							</div>
						</div>
					</div>
				</div>
				<div :class="['before' , 'a_bounce' , 'animate__animated' , active_a_5 ? 'animate__bounceInUp':'' ]" ref="a_5">
					<h4 class="home_title">书法练字加盟选六品书院</h4>
					<div class="about_us width_1200 margin_auto">
						<div class="us_data_info d_flex">
							<div class="flex_1 d_flex_column_center" v-for="item in use_data" :key="item.id">
								<h4>{{item.num}}</h4>
								<b></b>
								<p>{{item.desc}}</p>
							</div>
						</div>
					</div>
					<div class="about_us_pic width_1200 margin_auto">
						<div class="about_pic d_flex">
							<div class="about_pic_left d_flex">
								<div @mouseover="hoverGameOver(item.id)" :class="[ item.id === gActive ? 'active' : '' , 'pic_box']" v-for="item in groupImg" :key="item.id">
									<div></div>
									<img :src="baseImgUrl+item.url" alt="">
								</div>
							</div>
							<div class="group_info d_flex_column_start_center">
								<h4>总部风采</h4>
								<p>总部位于浙江省杭州市余杭区</p>
								<button @click="toAbout()">点击查看</button>
							</div>
						</div>
					</div>
					<div class="about_us_pic width_1200 margin_auto" style="margin-top:20px">
						<div class="about_pic d_flex">
							<div class="group_info student_info d_flex_column_end_center">
								<h4>加盟校区</h4>
								<p>六品书院加盟校高达800多所</p>
								<button @click="toSchool()">点击查看</button>
							</div>
							<div class="about_pic_left d_flex">
								<div @mouseover="hoverSchoolOver(item.id)" :class="[ item.id === sActive ? 'active' : '' , 'pic_box']" v-for="item in schoolImg" :key="item.id">
									<div></div>
									<img :src="baseImgUrl+item.url" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="home_4" :style="'background:url('+bg4+');backgroundSize:cover;backgroundRepreat:no-repreat;'">
				<div class="width_1200 margin_auto boss_info">
					<h4 class="home_title fff">创始人李六军带队研发，打造适合孩子的书法课程</h4>
					<p class="fff desc">团队业界知名的书法大咖团队，深厚的少儿书法教育经验</p>
					<div class="boss_profile d_flex justify_content_between">
						<div class="boss_item" v-for="(item) in teacherList" :key="item.id">
							<img :src="baseImgUrl + item.url" alt="">
							<div :class="['boss_item_content']" :style="'background:url('+baseImgUrl+'boss_item_bg.png);background-size:contain;'">
								<p v-for="(i , idx) in item.content" :key="idx">
									<b></b>
									<span>{{i}}</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="home_5">
				<div class="width_1200 margin_auto">
					<h4 class="home_title">企业荣誉</h4>
					<div class="company_honor">
						<div>
							<swiper ref="mySwiper" :options="swiperOption" class="honnor_swiper">
								<swiper-slide v-for="(item) in honors" :key="item.id">
									<img class="s_img" :src="baseImgUrl + item.url" alt="">
								</swiper-slide>
								<div class="swiper-pagination" slot="pagination"></div>
							</swiper>
						</div>
						<div class="swiper-button-next" @click="next()">
							<img :src="baseImgUrl + 'honnor_right.png'" alt="" />
						</div>
						<div class="swiper-button-prev" @click="prev()">
							<img :src="baseImgUrl + 'honnor_left.png'" alt="" />
						</div>
					</div>
				</div>
			</div>
			<div class="home_6" v-if="articleList.length >0">
				<h4 class="home_title">新闻中心</h4>
				<div class="news width_1200 margin_auto d_flex justify_content_between">
					<div class="news_container" v-for="(item,index) in articleList" :key="index">
						<div class="news_title d_flex justify_content_between">
							<h5>{{item.name}}</h5>
							<span @click="toNews()">查看更多>></span>
						</div>
						<div class="news_lists">
							<div class="news_item d_flex justify_content_between" @click="toDetail(itemChild.id)" v-for="(itemChild,idx) in item.seo_article" :key="idx">
								<div>
									<p class="line_one_clamp">{{itemChild.title}}</p>
									<span class="line_one_clamp" v-if="itemChild.description">{{itemChild.description}}</span>
								</div>
								<span>{{itemChild.updated_at | timeFlter}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="league_container">
			<div :class="[position === 'fixed' ? 'fixed' : 'relative' , 'league_position']">
				<League @openPop="openPop()" :baseInfo="baseInfo"/>
			</div>
		</div>
		<div ref="footer_container">
			<Footer :baseInfo="baseInfo"/>
		</div>
		<Top :baseInfo="baseInfo" v-show="isTopShow" @openPop="openPop()"/>
		<Form v-show="isShow" @closePop="closePop()"/>
	</div>
</template>

<script>
import {config} from "../config";
import mixin from '../utils/mixin';
import * as api from '../utils/api';
export default {
	mixins:[mixin],
	name: "Home",
	components: {

	},
	data(){
		return {
			baseImgUrl : config.baseImgUrl,
			bg1:config.baseImgUrl + 'home_bg_1.png',
			bg2:config.baseImgUrl + 'home_bg_2.png',
			bg3:config.baseImgUrl + 'home_bg_3.png',
			bg4:config.baseImgUrl + 'boss_bg.png',
			teamWorkBg:config.baseImgUrl + 'team_work_bg.png',
			tabs_card_bg:config.baseImgUrl+'tabs_card_bg.png',

			tea_list:[
				{id:1,url:'home_edu_1.png',title:'双师教学模式',content1:'0门槛办学',content2:'专业复制师资' , content3:'线上名师教学，保障教学质量；线下老师辅导，保障教学效果'},
				{id:2,url:'home_edu_2.png',title:'智能化平台',content1:'书法SAAS平台',content2:'运营数据化', content3:'打通机构/老师/学生3个端口，提升管理效率，优化教学体验'},
				{id:3,url:'home_edu_3.png',title:'科学练字法',content1:'循序渐进提升',content2:'让过程简单', content3:'书法练字投入成本低，一间教室，几张桌椅，即可开课'},
				{id:4,url:'home_edu_4.png',title:'引流电商用户',content1:'巨量精准用户',content2:'合作轻松触达', content3:'书法教育O2O平台整合六品堂及旗下产品的巨量书法学习用户，流量不愁轻松招生'}
			],
			use_data:[
				{id:1, num:'5000万+' , desc:'产品消费用户'},
				{id:2, num:'1000万+' , desc:'书法教育用户信任品牌'},
				{id:3, num:'3000家+' , desc:'品牌加盟校区'},
				{id:4, num:'600名+' , desc:'项目服务团队'},
				{id:5, num:'15年+' , desc:'少儿书法教育经验'}
			],
			gActive:1,
			sActive:1,
			tabCardActive:0,
			groupImg:[{id:1 , url:'home_group_1.png'} , {id:2 , url:'home_group_2.png'} , {id:3 , url:'home_group_3.png'}],
			schoolImg:[{id:1 , url:'home_school_1.png'} , {id:2 , url:'home_school_2.png'} , {id:3 , url:'home_school_3.png'}],
			teacherList:[
				{id:1 , url:'home_teacher_1.png' , content:['六品堂·六品书院创始人' ,'当代书法艺术院（文旅部业务主管）理事长' ,'浙江省硬笔书法家协会副主席' ,'浙江省书法研究会书法教育推广中心主任', '原《中国篆刻·书画教育》杂志执行副主编' ]} ,
				{id:2 , url:'home_teacher_2.png' , content:['六品书院教研主任' , '中国硬笔书法协会会员' , '浙江省书法研究会理事' , '浙江省硬笔书法家协会理事']} ,
				{id:3 , url:'home_teacher_3.png' , content:['六品书院硬笔教研负责人' , '六品书道院教研委员会主任' , '中国硬笔书法协会会员' , '中国硬笔书法协会高级注册教师']} ,
				{id:4 , url:'home_teacher_4.png' , content:['六品书院软笔教研负责人' , '六品书院教学部负责人' , '中国硬笔书法协会会员' , '文旅部艺科所考级中心考级官']} ,
				// {id:5 , url:'home_teacher_5.png' , content:['中国书法家协会会员' , '当代书法艺术院培训中心特约教师' , '10年书法训练' , '5年书法教学经验']}
			],
			honors:[
				{id:1 , url:'home_honor_1.png'},
				{id:2 , url:'home_honor_2.png'},
				{id:3 , url:'home_honor_3.png'},
				{id:4 , url:'home_honor_4.png'},
				{id:5 , url:'home_honor_5.png'},
				{id:6 , url:'home_honor_6.png'},
			],

			swiperOption: {
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				slidesPerView: 5,
				spaceBetween: 30,
				loop: true,
				autoplay: false,
				grabCursor:true,
			},
			clientHeight:0,
			position:'',
			isTopShow:false,
			timer1:null,
			time2:null,
			articleList:[],
		}
	},
	filters:{
		timeFlter(val){
			return val.split(' ')[0]
		}
	},
	created(){
		this.getArticle();
	},
	mounted(){
		let _this = this;
		this.timer1 = setInterval(function(){
			_this.gActive = _this.gActive+1
			if(_this.gActive > 3){
				_this.gActive = 1;
			}
		},3000)
		this.time2 = setInterval(function(){
			_this.sActive = _this.sActive+1
			if(_this.sActive > 3){
				_this.sActive = 1;
			}
		},5000)
	},
	computed:{
		swiper(){
			return this.$refs.mySwiper.$swiper
		}
	},
	methods:{
		hoverGameOver(id){
			this.gActive = id
		},
		hoverSchoolOver(id){
			this.sActive = id
		},
		tabMouseOver(index){
			this.tabCardActive = index
		},
		// tabMouseOut(){
		// 	this.tabCardActive = 0
		// },
		prev () {
			this.$refs.mySwiper.$swiper.slidePrev()
		},
		next () {
			this.$refs.mySwiper.$swiper.slideNext()
		},
		toCourse(){
			this.$router.push({
				path:'/course'
			})
		},
		toJiaMeng(){
			window.open('https://www.liupinedu.com/experience.html')
		},
		toAbout(){
			this.$router.push({
				path:'/about'
			})
		},
		toSchool(){
			this.$router.push({
				path:'/teamwork'
			})
		},
		toNews(){
			this.$router.push({
				path:'/news'
			})
		},
		toDetail(id){
			this.$router.push({
				path:'/newsDetail',
				query:{
					id:id
				}
			})
		},
		getArticle(){
			let params = {
				type:1,
			}
			this.getData(params);
		},
		getData(params){
			api.article(params).then(res=>{
				if(res.status === 0){
					if(res.data.length > 2){
							this.articleList = res.data.slice(0,2);
						}else{
							this.articleList = res.data;
						}
						this.articleList.forEach(item=>{
							if(item.seo_article.length > 4){
								item.seo_article = item.seo_article.slice(0,4);
							}
						})
				}
			})
		},
	},

};
</script>

<style lang="scss" scoped>

	.home_1{
		height: 500px;
		.home_1_con{
			height:100%;
			.video_box{
				.v_container{
					position: relative;
					width:620px;
					height:330px;
					video{
						position: absolute;
						top:0;
						left:0;
						z-index: 1;
						width:610px;
						height:320px;
						background: #000;
					}
					.v_bg{
						width:0px;
						height:0px;
						position: absolute;
						bottom:0;
						right:0;
						transition: all 1s ease 0s;
					}
				}
				.v_container:hover{
					.v_bg{
						width:610px;
						height:320px;
					}
				}
			}
			.right{
				padding-left:50px;
				font-size:18px;
				color:#333;
				h4{
					font-size:36px;
					font-weight: bold;
					transition: all 1s ease 0s;
					transform: scale(1);
					text-align: left;
				}
				b{
					width:80px;
					height:4px;
					border-radius: 2px;
					display: block;
					margin:20px 0;
					transition: all 1s ease 0s;
					transform: scale(1);
				}
				div{
					p{
						line-height: 27px;
						text-align: left;
						transition: all 1s ease 0s;
						transform: scale(1);
					}
					p:last-child{
						margin-top:10px;
					}
				}

			}
			.right:hover {
				h4 , b {
					transform: scale(1.1);
				}
				b{
					width: 90px;
				}
				div{
					p{
						transform: scale(1.1);
					}
				}
			}
		}

	}
	.slogn{
		width:100%;
	}
	.home_2{
		width:100%;
		// height:1780px;
		// opacity: 0;
		.home_title{
			margin-top:88px;
		}
		.tixi{
			font-size:20px;
			color:#999;
			margin:20px auto 40px;
		}
		.course_banner_box{
			position: relative;
			margin-top:40px;
			overflow: hidden;
			.width_1200{
				width:100%;
				position: relative;
				z-index: -1;
			}
			.course_box_right{
				position: absolute;
				width:60%;
				height:100%;
				right:0;
				top:0;
				text-align: left;
				font-size: 18px;
				padding:50px 0 50px 70px;
				h4{
					font-size: 28px;
					font-weight: bold;
					color:#333;
				}
				p{
					margin:20px 0 40px 0;
					color:#333;
				}
				div{
					width:180px;
					text-align: center;
					height:40px;
					line-height: 40px;
					color:#333;
					border:1px solid #333;
					border-radius: 5px;
					cursor: pointer;
					transition: all linear .2s;
					transform: scale(1);
				}
			}
			.course_box_right:hover{
				div{
					transform: scale(1.1);
				}
			}
		}
		.home_game{
			margin-top:50px;
			background: #F3F3F3;
			border-radius: 10px;
			overflow: hidden;
			padding:20px;
			.right_img{
				width:680px;
				height:490px;
				border-radius: 10px;
				cursor: pointer;
			}
			.left{
				width: 460px;
				font-size: 16px;
				color:#333;
				text-align: left;
				.tabs_card{
					padding: 10px 30px 10px 10px;
					border-radius: 10px;
					width:445px;
					height:100px;
					position: relative;
					cursor: pointer;
					// transition: all linear .2s;
					.line_content{
						width:395px;
						h6{
							font-size: 18px;
							line-height: 24px;
							font-weight: bold;
							margin-left:10px;
						}
						p{
							font-size: 20px;
							line-height: 34px;
							// margin:4px 0 0 10px;
							margin-left:10px;
						}
					}
					.triangle{
						width:6px;
						height:12px;
						background: #B91829;
						position: relative;
						top:-18px;
						border-radius: 2px;
					}
					.triangle.one_line{
						top:-2px;
					}
				}
				// .tabs_card:hover{
				// 	background: #B91829;
				// 	color:#fff;
				// }
			}
		}
	}
	.a_bounce{
		opacity: 0;
	}
	.a_bounce.animate__bounceInUp{
		opacity: 1;
	}
	.home_3{
		.home_title{
			margin-top:100px;
		}
		width:100%;
		// height:1990px;
		font-size:18px;
		color:#333;
		// padding:60px 0;
		.teachers_layout{
			margin-top:50px;
			.t_layout_item_box{
				position: relative;
				cursor: pointer;
				box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
				.t_layout_item{
					width:300px;
					height:360px;
					background: #FFF;
					img{
						width: 98px;
						height:98px;
						margin-bottom: 10px;
					}
					b{
						width:60px;
						height:4px;
						border-radius: 2px;
						background: #B91829;
					}
					h4{
						font-size:24px;
						margin:15px 0;
						font-weight: bold;
					}
					div{
						p{
							line-height: 26px;
						}
					}
					.know_more{
						color: #B91829;
						margin-top:30px;
						transform: translateX(0);
						transition: all linear .2s;
					}
				}
				.t_layout_item_hover{
					position: absolute;
					opacity: 0;
					width:100%;
					height:100%;
					background: #B91829;
					top:0;
					left:0;
					display: flex;
					align-items: center;
					padding:50px;
					transition: all .5s ease 0s;
					p{
						color: #fff;
						font-size:20px;
						font-weight: bold;
						line-height: 50px;
					}
				}
			}

			.t_layout_item_box:hover{
				.t_layout_item_hover{
					// width:100%;
					// height:100%;
					opacity: 1;
				}
			}
		}
		.team_work{
			height: 440px;
			margin-top:60px;
			.team_work_content{
				width: 1078px;
				height: 378px;
				.team_work_item{
					width: 262px;
					height:184px;
					border-radius: 3px;
					overflow: hidden;
				}
				.item_first{
					// cursor: pointer;
					div{
						transition: all linear .3s;
						transform: translateX(0);
						span{
							font-size: 28px;
							color:#fff;
							font-weight: bold;
						}
						img{
							width: 30px;
							height:30px;
							margin-left:10px;
						}
					}

					b{
						width: 90px;
						height:4px;
						border-radius: 2px;
						background: #fff;
						margin-top:20px;
						display: block;
					}
				}
				.item_first:hover div{
					transform: translateX(10px);
				}
				.item_pic{
					position: relative;
					.el-image{
						width:100%;
						height:100%;
						transition: all linear .4s;
					}
					p{
						position: absolute;
						bottom: 0;
						width:100%;
						line-height: 46px;
						background: rgba(0,0,0,.6);
						text-align: center;
						color:#fff;
						font-size: 20px;
					}
				}
				.item_pic:hover{
					.el-image{
						transform: scale(1.2);
					}
				}
				.item_last{
					border:1px solid #F45750;
					border-radius: 3px 3px 60px 3px;
					background: #fff;
					p{
						margin-bottom: 13px;
						line-height: 28px;
					}
					button{
						cursor: pointer;
						width:200px;
						height:42px;
						font-size:18px;
						font-weight: bold;
						color:#B91829;
						background: #fff;
						border:1px solid #B91829;
						border-radius: 21px;
						transition: all linear .2s;
					}
					button:hover{
						background: #B91829;
						border:1px solid #B91829;
						color:#fff;
					}
				}
			}
		}
		.about_us{
			padding:60px 0;
			.us_data_info{
				div{
					height: 150px;
					border-left:1px solid #C4C4C4;
					h4{
						font-size: 28px;
						font-weight: bold;
						color:#B91829;
						transition: all 1s ease 0s;
						transform: scale(1);
					}
					b{
						display: block;
						width: 60px;
						height: 4px;
						background: #B91829;
						margin:13px 0;
						transition: all 1s ease 0s;
					}
				}
				div:last-child{
					border-right: 1px solid #C4C4C4;
				}
				div:hover{
					h4{
						transform: scale(1.2);
					}
					b{
						width:70px;
					}
				}
			}
		}
		.about_pic{
			border-radius: 10px;
			overflow: hidden;
			.about_pic_left{
				overflow: hidden;
			}
			.pic_box{
				width:100px;
				height:240px;
				position: relative;
				transition: all 0.3s linear 0s;
				div{
					position: absolute;
					width:100%;
					height:100%;
					background: rgba(0,0,0,.5);
					top:0;
					left:0;
				}

				img{
					object-fit: cover;
					width:100%;
					height:100%;
				}
			}
			.pic_box:nth-child(2){
				div{
					background: rgba(176,148,117,.5)
				}
			}
			.pic_box.active{
				width:620px;
				div{
					background: rgba(0,0,0,0);
				}
				img{
					width:620px;
				}
			}
			.group_info , .student_info{
				width:380px;
				height:240px;
				background:#B91829;
				font-size: 18px;
				color:#fff;
				padding:25px;
				border-radius: 0 10px 10px 0;
				h4{
					font-weight: bold;
					font-size: 26px;
				}
				p{
					margin:15px 0 25px 0;
				}
				button{
					width: 170px;
					height:45px;
					border-radius: 22px;
					border:1px solid #fff;
					font-size: 18px;
					font-weight: bold;
					background: transparent;
					color:#fff;
					cursor: pointer;
					transform: scale(1.1);
					transition: all linear .2s;
				}
			}
			.group_info:hover{
				button{
					transform: scale(1);
				}
			}
			.student_info{
				border-radius: 10px 0 0 10px;
				background: #666;
			}
		}
	}
	.home_4{
		width:100%;
		height:410px;
		margin-top:70px;
		.boss_info{
			padding-top:50px;
			position: relative;
			.desc{
				margin:20px 0;
			}
			.boss_profile{
				margin-top:50px;
				.boss_item{
					width:214px;
					height:294px;
					position: relative;
					perspective: 1000px;
					img{
						transform: rotateY(0);
    					transition: all 0.50s ease-in-out 0s;
					}
					.boss_item_content{
						position: absolute;
						top:0;
						left:0;
						height:100%;
						width:100%;
						// background: rgba(185,24,41,.8);
						padding:10px;
						border-radius: 5px;
						transform: rotateY(90deg);
    					transition: all 0.5s ease-in-out 0s;
						p{
							text-align: left;
							line-height: 22px;
							margin-top:6px;
							text-transform: uppercase;
							// display: flex;
							// align-items: center;
							b{
								width:4px;
								height:4px;
								border-radius: 2px;
								background: #fff;
								display: inline-block;
								vertical-align: middle;
							}
							span{
								color: #fff;
								font-size:15px;
								margin-left:7px;
								vertical-align: middle;
								// display: inline-block;
							}
						}
					}
				}
				.boss_item:hover img{
					transform: rotateY(-90deg);
				}
				.boss_item:hover .boss_item_content{
					transform: rotateY(0);
				}
			}
		}
	}
	.home_5{
		.home_title{
			margin-top:150px;
		}
		.company_honor{
			margin-top:50px;
			position: relative;
			img.s_img{
				width:200px;
				height:160px;
			}
			.swiper-button-next{
				right:-80px;
			}
			.swiper-button-prev{
				left:-80px;
			}
			.swiper-button-next , .swiper-button-prev{
				position: absolute;
				width:58px;
				height:58px;
				img{
					width:100%;
					height:100%;
				}
			}
			.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
				content: '';
			}
			.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
				content: '';
			}
		}
	}
	.home_6{
		margin-bottom:80px;
		.home_title{
			margin-top:88px;
		}
		.news{
			margin-top:50px;
			.news_container{
				width: 580px;
				box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
				background: #fff;
				font-size: 18px;
				color:#333;
				padding:10px 20px;
				border-radius: 8px;
				.news_title{
					line-height: 40px;
					h5{
						font-size: 24px;
						font-weight: bold;
						border-bottom:2px solid #B91829;
					}
					span{
						color:#B91829;
						font-size: 16px;
						cursor: pointer;
					}
				}
				.news_lists{
					.news_item{
						cursor: pointer;
						padding:15px 0;
						border-bottom: 1px solid #C4C4C4;
						div{
							text-align: left;
							width:75%;
							p{
								line-height: 28px;
							}
							span{
								font-size: 14px;
								line-height: 26px;
								// display: block;
								// min-height: 30px;
							}
						}
						span{
							line-height: 28px;
						}
					}
					.news_item:hover{
						color:#B91829;
					}
					.news_item:last-child{
						border:none;
					}
				}
			}
		}
	}
</style>
