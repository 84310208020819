<!--
 * @Author: your name
 * @Date: 2021-04-28 14:47:29
 * @LastEditTime: 2021-05-27 21:10:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/components/League.vue
-->
<template>
	<div :class="position">
		<div class="league_bg">
			<img class="league_img" :src="baseImgUrl+'league.png?v=1.0'" alt="">
			<div class="width_1200 league_box">
				<div class="box_rt d_flex justify_content_end align_items_center">
					<div class="btn fff" @click="openPop()">获取书法加盟秘笈</div>
					<div class="qr_code_box">
						<img class="qr_code" v-if="baseInfo" :src="baseInfo.qrcode" alt="">
						<p class="fff">加盟二维码</p>
					</div>
				</div>
				<div class="wg_box">
					<div>
						<span>书法练字加盟认准六品书院</span>
					</div>
					<img class="wanggang" :src="baseImgUrl + 'wanggang.png'" alt="">
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import {config} from '../config';
export default {
	data () {
		return {
			baseImgUrl:config.baseImgUrl,
			position:'relative'
		};
	},

	props:{
		footerTop:{
			type:Number,
			default:0
		},
		baseInfo:{
			type:Object,
			default:null
		}	
	},

	created(){
		
	},

	mounted(){
		
	},

   	methods: {
		openPop(){
			this.$emit('openPop');
		},
	}
}

</script>
<style lang='scss' scoped>
	.fixed{
		position: fixed;
		bottom:0;
		left:0;
	}
	.relative{
		position: relative;
	}
	.league_bg{
		position: relative;
		.league_img{
			width:100%;
			display: block;
			min-height: 116px;
		}
		.league_box{
			position: absolute;
			left:50%;
			top:0;
			margin-left:-600px;
			height:100%;
			.box_rt{
				height:100%;
				.btn{
					width: 300px;
					height: 50px;
					background: linear-gradient(180deg, #FF3C51 0%, #DE1C31 42%, #B91829 100%);
					box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);
					border-radius: 10px;
					margin-right: 50px;
					line-height: 50px;
					font-size: 22px;
					font-weight: bold;
					cursor: pointer;
				}
				.qr_code_box{
					margin-right:50px;
					.qr_code{
						width: 90px;
						height:90px;
					}
					p{
						font-size: 10px;
					}
				}
			}
		}
		.wg_box{
			position: absolute;
			bottom:0;
			left:-80px;
			height:100%;
			div{
				height:100%;
				display: flex;
				align-items: center;
				padding-left:200px;
				span{
					font-size: 32px;
					font-weight: bold;
					color:#fff;
				}
			}
			img{
				position: absolute;
				bottom:0;
				left:0;
				width:200px;
				height:162px;
			}
		}
	}
</style>