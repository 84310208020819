<!--
 * @Author: your name
 * @Date: 2021-04-21 16:17:32
 * @LastEditTime: 2021-05-27 21:10:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/components/Footer.vue
-->
<template>
	<div class="footer">
		<div class="f_container width_1200 margin_auto d_flex justify_content_between">
			<div class="menu d_flex justify_content_around">
				<div v-for="(item , index) in menuLists" :key="index">
					<p @click="toLink(i.url , i.id)" v-for="(i , idx) in item" :key="idx" :style="{cursor:+i.url != '' ? 'pointer' : ''}">{{i.name}}</p>
				</div>
			</div>
			<div class="d_flex qr_code_info">
				<div>
					<div class="d_flex tel_info">
						<img class="tel_icon" :src="baseImgUrl + 'tel_icon.png'" alt="">
						<div>
							<h4 v-if="baseInfo">{{baseInfo.join_tel}}</h4>
							<p>六品书院书法练字加盟热线 </p>
						</div>
					</div>
					<div class="f_time">
						<p>工作时间：9：00-21：00</p>
						<p>法定节假日除外  
							<!-- <span style="margin-left:20px;">全国免加盟费</span> -->
						</p>
					</div>
				</div>
				<img class="qr_code" v-if="baseInfo" :src="baseInfo.qrcode" alt="">
			</div>
		</div>
		<div class="margin_auto copy_right">
			<p>浙江六品堂教育科技有限公司 Copyright ©<a style="color:#d7d7d7;" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备19049119号-1</a></p>
			<p>浙江省杭州市余杭区欧美金融城T4美国中心42-45楼</p>
			<p>投资有风险 加盟需谨慎</p>
		</div>
	</div>
</template>

<script>
import {config} from '../config';
export default {
	props:{
		baseInfo:{
			type:Object,
			default:null
		}
	},
	data () {
		return {
			baseImgUrl:config.baseImgUrl,
			qrCode:"",
			joinTel:'',
			menuLists:[
				[
					{name:'首页' , id:1 , url:'/'}
				],
				[ 
					{name:'课程体系' , id:2 , url:'' } , 
					{name:'硬笔课程' , id:3 , url:'/course?type=1'},
					{name:'软笔课程' , id:4 ,  url:'/course?type=2'}
				],
				[
					{name:'作品展示' , id:5 , url:''} ,
					{name:'学员作品' , id:6 , url:'/production?type=1'},
					{name:'老师作品' , id:7 , url:'/production?type=2'},
					{name:'书法作品' , id:8 , url:'/production?type=3'}
				]
			],
			path:''
		};
	},

	created(){
		if(this.$route.path){
			this.path = this.$route.path
		}
		// if(localStorage.getItem('info')){
		// 	this.qrCode = JSON.parse(localStorage.getItem('info')).qrcode;
		// 	this.joinTel = JSON.parse(localStorage.getItem('info')).join_tel;
		// }
	},

	methods: {
		toLink(url , id){
			if(url){
				switch (id) {
					case 1:
						if(url !== this.path){
							this.$router.push({
								path:url
							})
						}
						break
					default:
						if(this.$route.query.type == url.split('?')[1].split('=')[1] && this.path == url.split('?')[0]){
							return
						}
						this.$router.push({
							path:url
						});
				}
			}
		}
	}
}

</script>
<style lang='scss' scoped>
	.footer{
		background: #333;
		height:300px;
		padding-top:20px;
		color:#D7D7D7;
		.f_container{
			.menu{
				width: 45%;
				div{
					p{
						line-height: 28px;
					}
					p:hover{
						color: #fff;
					}
				}
			}
			.qr_code_info{
				justify-content: flex-end;
				font-size: 14px;
				.tel_info{
					.tel_icon{
						width:40px;
						height:40px;
					}
					div{
						text-align: left;
						margin-left:20px;
						h4{
							font-size: 22px;
							font-weight: bold;
							margin-bottom: 6px;
						}
					}
				}
				
				.f_time{
					margin-top:30px;
					p{
						line-height: 26px;
						text-align: left;
					}
				}
				.qr_code{
					width: 120px;
					height: 120px;
					margin-left:100px;
				}
			}
		}
		.copy_right{
			margin-top:30px;
			padding-top:20px;
			font-size: 14px;
			width:1000px;
			border-top:1px solid #707070;
			p{
				line-height: 22px;
			}
		}
	}
</style>