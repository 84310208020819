/*
 * @Author: your name
 * @Date: 2021-04-16 16:24:47
 * @LastEditTime: 2021-05-28 18:22:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_dev_pc/src/main.js
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import MetaInfo from 'vue-meta-info'

Vue.config.productionTip = false;

import {
	Image,
	Pagination,
	Input,
	Message
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Image);
Vue.use(Pagination);
Vue.use(Input);
Vue.use(MetaInfo)

Vue.prototype.$message = Message;


import animated from 'animate.css';
Vue.use(animated);


import "./style/reset.css";
import "./style/common.css";

import * as api from './utils/api';

new Vue({
	router,
	store,
	data:{
		path:'',
		keywords:'',
		description:'',
		title:''
	},
	metaInfo: {
		title: 'We Inc',
		meta: [
			{
				name: 'keywords',
				content: '关键字1,关键字2,关键字3'
			},
			{
				name: 'description',
				content: '这是一段网页的描述'
			}
		]
	},
	created(){
		localStorage.removeItem('baseInfo');
		let path = this.$route.path;
		this.path = path != '/' ? path : localStorage.getItem('path');
		this.getSeo();
		router.beforeEach((to,from,next)=>{
			this.path = to.path;
			localStorage.setItem('path',to.path);
			this.getSeo();
			next();
		});
	},
	methods:{
		getSeo(){
			api.setSeo().then(res=>{
				if(res.status === 0){
					for(var i in res.data){
						if(this.path === i){
							this.keywords = res.data[i].seo_keywords;
							this.description = res.data[i].seo_description;
							this.title = res.data[i].seo_title;
						}
					};
					document.title = this.title;
					document.querySelector('meta[name="keywords"]').setAttribute('content', this.keywords);
                    document.querySelector('meta[name="description"]').setAttribute('content', this.description);
				}
			})
		},
	},
	render: (h) => h(App),
	mounted () {
		document.dispatchEvent(new Event('render-event'))
	}
}).$mount("#app");
