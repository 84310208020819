/*
 * @Author: your name
 * @Date: 2021-05-26 17:53:20
 * @LastEditTime: 2021-05-27 19:56:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/store/index.js
 */
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
	state: {
		
	},
	mutations: {
		
	},
	actions: {
		
	},
});
