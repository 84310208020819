/*
 * @Author: your name
 * @Date: 2021-04-29 21:22:39
 * @LastEditTime: 2021-05-29 15:40:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/utils/mixin.js
 */
// import {throttle} from '../utils/util';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
import Swiper2, {Pagination , Navigation , Autoplay} from 'swiper';
Swiper2.use([Pagination, Navigation , Autoplay]);
import Header from "@/components/Header.vue";
import Footer from '@/components/Footer.vue';
import Top from '@/components/Top.vue';
import League from '@/components/League.vue'
import * as api from '../utils/api';
let vm = null;
let mixin = {
	components:{
		Swiper,
		SwiperSlide,
		Header,
		Footer,
		Top,
		League,
		"Form":()=>import('../components/Form')
	},
	data(){
		return {
			isShow : false,
			position:'',
			clientHeight:0,
			isTopShow:false,
			path:'',
			active_a_1:false,
			active_a_2:false,
			active_a_3:false,
			active_a_4:false,
			active_a_5:false,
			swiperBanner:{
				autoplay: {
					delay: 3000, 
					stopOnLastSlide: true, //如果设置为true，当切换到最后一个slide时停止自动切换。
					disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。
					waitForTransition: true, //等待过渡完毕。自动切换会在slide过渡完毕后才开始计时。
				},
				loop:true,
				pagination: {
					el: '.swiper-pagination',
				},
				on:{
					click(){
						const realIndex = this.realIndex;
						vm.handleClickSlider(realIndex);
					}
				}
			},
			banner:[],
			gameLists:[],
			teamworkLists:[],
			teamRequestPage:{
				count:0,
				pageSize:6,
				currentPage:1
			},
			baseInfo:null,
		}
	},
	computed:{
		mySwiperBanner(){
			if(this.$refs.mySwiperBanner){
				return this.$refs.mySwiperBanner.$swiper
			}
		}
	},
	created(){
		vm = this;
		this.clientHeight = document.documentElement.clientHeight;
		this.path = this.$route.path;
		this.getadver();
		if(JSON.parse(localStorage.getItem('baseInfo'))){
			this.baseInfo = JSON.parse(localStorage.getItem('baseInfo'));
		}else{
			this.getBaseInfo();
		}
	},
	mounted(){
		window.addEventListener('scroll' , this.scroll );
	},

	destroyed(){
		window.removeEventListener('scroll',this.scroll);
	},
	
	methods:{
		closePop(){
			this.isShow = false
		},
		openPop(){
			this.isShow = true;
		},
		on_swiper_enter(){
			if(this.$refs.mySwiperBanner){
				this.mySwiperBanner.autoplay.stop();
			}
		},
		on_swiper_leave(){
			if(this.$refs.mySwiperBanner){
				this.mySwiperBanner.autoplay.start();
			}
		},
		scroll(e){
			let scrollTop = e.srcElement.scrollingElement.scrollTop;
			let top = this.$refs.footer_container.getBoundingClientRect().top;   //footer组件距离屏幕顶部的距离
			if(scrollTop > 200){
				this.position = 'fixed';
				this.isTopShow = true;
				if(top <= this.clientHeight){
					this.position ="relative";
				}
			}else{
				this.position = '';
				this.isTopShow = false;
			}
			if(this.path === '/' || this.path === '/home'){
				let top1 = this.$refs.a_1.getBoundingClientRect().top; 
				let top2 = 0;
				if(this.$refs.a_2){
					top2 = this.$refs.a_2.getBoundingClientRect().top; 
				}
				let top3 = this.$refs.a_3.getBoundingClientRect().top; 
				let top4 = this.$refs.a_4.getBoundingClientRect().top; 
				let top5 = this.$refs.a_5.getBoundingClientRect().top; 
				if(top1 < 500){
					this.active_a_1 = true
				}
				if(this.$refs.a_2){
					if(top2 < 500){
						this.active_a_2 = true
					}
				}
				if(top3 < 500){
					this.active_a_3 = true
				}
				if(top4 < 500){
					this.active_a_4 = true
				}
				if(top5 < 500){
					this.active_a_5 = true
				}
			}
		},
		getadver(){
			let belong = 0;
			switch(this.path){
				case '/home':{
					belong = 1;
					break
				}
				case '/course':{
					belong = 2;
					break
				}
				case '/history':{
					belong = 3;
					break
				}	
				case '/production':{
					belong = 4;
					break
				}
				case '/teamwork':{
					belong = 5;
					break
				}
				case '/about':{
					belong = 6;
					break
				}		
			}
			let params = {
				type:1,
				belong
			}
			if(belong === 5){
				Object.assign(params , {
					pageSize:this.teamRequestPage.pageSize,
					currentPage:this.teamRequestPage.currentPage,
				})
			}
			api.adver(params).then(res=>{
				if(res.status === 0){
					if(res.data.length > 0){
						res.data.forEach(item=>{
							if(item.location === 1){
								//banner广告位
								this.banner = item.adver
							}
							if(item.location === 2){
								//书法大赛位置
								this.gameLists = item.adver.slice(0,5);
							}
							if(item.location === 3){
								//合作加盟页面
								this.teamworkLists = item.adver;
								this.teamRequestPage = item.requestPage;
							}
						})
					}
				}
			})
		},
		teamChange(val){
			if(this.path === '/teamwork'){
				let params = {
					type:1,
					pageSize:this.teamRequestPage.pageSize,
					currentPage:val,
					belong:5
				}
				this.getAdver(params);
			}
		},
		getAdver(params){
			api.adver(params).then(res=>{
				if(res.status === 0){
					if(res.data.length > 0){
						res.data.forEach(item=>{
							if(item.location === 3){
								//合作加盟页面
								this.teamworkLists = item.adver;
								this.teamRequestPage = item.requestPage;
							}
						})
					}
				}
			})
		},
		handleClickSlider(index){
			let url = this.banner[index].url;
			if(url){
				window.location.href = url
			}
		},
		adDetail(url){
			if(url){
				window.location.href = url
			}
		},
		getBaseInfo(){
			api.infos().then(res=>{
				if(res.status === 0){
					if(res.data){
						this.baseInfo = res.data;
						localStorage.setItem('baseInfo',JSON.stringify(res.data));
					}
				}
			})
		},
	},
	directives: {
		swiper: directive
	}
}
export default mixin

